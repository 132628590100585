<template>
  <v-app>
    <PageHeader/>
    <v-main>
      <loading v-if="loading1 == true"/>
      <div class="home" v-if="loading1 == false">
        <div id="app">
          <h1 class="text-center header pb-lg-5 pt-lg-5">Camp Updates</h1>
          <v-layout align-center justify-center>
            <v-carousel
                :cycle=true
                style="width:1400px;"
            >
              <v-carousel-item
                  v-for="(post,i) in posts"
                  :key="i"
                  class="demo"
                  reverse-transition="fade"
                  transition="fade"
                  cycle="true"
              >
                <img class="carousel" :src="newsImage(i)" alt="newImage">
                <v-row
                    class="fill-height pa-8 text-center"
                    align="center"
                >
                  <v-col cols="12" class="pl-5">
                    <h1 class="white--text">{{ post.title }}</h1>
                  </v-col>
                  <v-col cols="12" class="pl-5">
                    <p class="text-h6 white--text text-center">By:{{ post.author }}</p>
                  </v-col>
                  <v-col cols="12" class="pl-5">
                    <p class="text-h6 white--text text-center">{{ post.shortDescription }}</p>
                  </v-col>
                  <v-col cols="12" class="pl-5">
                    <p class="white--text">Posted On {{ post.createdAtFixed }}</p>
                  </v-col>
                  <v-col cols="12" class="pl-5">
                    <v-btn
                        depressed
                        color="primary"
                        @click="fullPost(post)"
                    >
                      Click Here To See Full Article
                    </v-btn>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-layout>
          <!--          <v-row justify="center">-->
          <!--            <v-col v-for="post in posts" :key="post.id" class="text-center" cols="10" sm="6" md="5">-->
          <!--              <v-card elevation="2" style="display: grid;" class=" card" height="400px">-->
          <!--                <h2 class="pt-4">{{ post.title }}</h2>-->
          <!--                <div id="container">-->
          <!--                  <p>By: {{ post.author }}</p>-->
          <!--                  <p>{{ post.shortDescription }}</p>-->
          <!--                  <p>Posted On {{ post.createdAtFixed }}</p>-->
          <!--                  <v-btn-->
          <!--                      depressed-->
          <!--                      color="primary"-->
          <!--                      @click="fullPost(post)"-->
          <!--                  >-->
          <!--                    Click Here To See Full Article-->
          <!--                  </v-btn>-->
          <!--                </div>-->
          <!--              </v-card>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </div>
        <v-card elevation="0" class="pb-10  home">
          <v-row justify="center">
            <v-col class="text-center" cols="12" sm="12" md="12" lg="8">
              <h3 class="text-center pt-10 ml-10 mr-10"> The CampMail system allows parents to communicate with campers
                via
                a unique email system Parents can choose to sign up for this system and buy email credits at the cost of
                ${{ $store.state.config.configurations.mailCreditPrice }} apiece. </h3>
              <h3 class="text-center ml-5 mr-5">Parents can email campers through this system at the cost of 1 credit
                per
                email. These emails are printed in the office and distributed daily during breakfast (excluding
                Shabbat). </h3>
              <h3 class="text-center pl-5 pl-5">When sending an email, parents can choose to provide their child with
                the
                option to send a reply email at the cost of an additional credit. Campers that receive a reply email
                note
                can send an email directly back to the parent from computers located in the game room.</h3>
            </v-col>
          </v-row>
        </v-card>

        <h1 class="header">Click Here For More Info</h1>
        <v-img
            src="../assets/home_more_info_banner.jpg"
            aspect-ratio="4"
            @click="moreinfo"
        ></v-img>
        <div>
        </div>
      </div>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>
// @ is an alias to /src
import PageHeader from "@/components/Header";
import Footer from "@/components/Footer";
import Posts from "../services/Posts";
import moment from "moment";
import loading from "../components/Loading";

export default {
  name: "Home",
  components: {PageHeader, Footer, loading},
  data() {
    return {
      posts: null,
      loading1: true,
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
    };
  },
  computed: {
    currentBranch() {
      return this.$store.state.config.configurations.currentBranchId
    }
  },
  async mounted() {
      if (!this.currentBranch) {
        await this.$store.dispatch("config/config");
      }
      this.posts = (await Posts.getPosts([
        {
          name: "limit",
          value: 10,
        },
        {
          name: "branchid",
          value: this.currentBranch
        },
        {
          name: "orderby",
          value: 'createdAt'
        },
        {
          name: "status",
          value: 1
        }
      ])).data;
      this.posts.forEach(post => {
        post.createdAtFixed = moment(post.createdAt).format("LL");
      });



    this.loading1 = false;
  },
  methods: {
    newsImage(number) {
      return (number % 2 === 0) ? "/images/news/news.jpg" : "/images/news/news2.jpg";
    },
    moreinfo() {
      this.$router.push({name: "MoreInfo"});
    },
    fullPost(post) {
      this.$router.push({
        name: "FullArticle",
        params: {post},
        query: {id: post.postId}
      });
    }
  }
};
</script>
<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.home {
  background-color: #f1eff2;
}

.header {
  font-family: 'Open Sans', sans-serif;
}

.card {
  background-image: url("/images/card_background.jpg");
}

img.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  filter: brightness(40%);
  z-index: -1;
  object-fit: cover;
}

#container {
  margin: auto;
}

</style>